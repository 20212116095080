:root {
  --background: #f0f2f5;
  --red: #E52E4D;
  --blue: #5429cc;
  --green: #1ca48f;
  --orange: #C4890C;
  --blue-light: #6933FF;
  --pink0: #F9004B;
  --pink-1: #F92565;
  --pink1: #D40040;
  --gray-100: #e1e1e6;
  --gray-300: #a8a8b3;
  --gray-850: #c0e1e9;
  --gray-900: #121214;
  --gray-700: #29292e;
  --cyan-500: #61dafb;
  --yellow-500: #eba417;

  --text-title: #363f5f;
  --text-body: #969cb3;
  --shape: #FFFFFF;
  --primary-color: #1f3f77;
  --primary-colorB: #1E658F;
  --primary-colorC: #1C7985;
  --primary-colorD: #3368C4;
  --primary-colorE: #6E8EC4;
  --gray: #707070;
  --white: #FBFBFB;
}

* {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
}

html {
  @media(max-width: 1080px) {
    font-size: 93.75%;
  }

  @media(max-width: 720px){
    font-size: 87.5%
  }
}

body {
  background-color: var(--background) !important;
  -webkit-font-smoothing: antialiased;
}

body, input, textarea, button {
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
}

h1, h2, h3,h4,h5,h6, strong {
  font-weight: 600;
}

button, a {
  cursor: pointer;
}


.ReactModalPortal {
  color: var(--text-body);
  overflow: hidden !important;
}

.ReactModalPortal h1 {
  font-size: 37.89px;
}

.ReactModalPortal p {
  font-size: 16px
}

#link-primary {
  min-width: 4rem;
  color: var(--white);
  border-radius: 4px;
  background-color: var(--pink1);
  transition: filter 0.2s;
  padding: 16px;
}

#link-secondary {
  min-width: 4rem;
  color: var(--white);
  border-radius: 4px;
  background-color: var(--pink0);
  transition: filter 0.2s;
  padding: 16px;
}

#link-primary:hover, #link-secondary:hover {
  filter: brightness(90%);
}

input {
  padding: 16px;
  height: 3rem;
  width: 100%;
  margin-bottom: 8px;
  border: none;
  border-radius: 4px;
  color: var(--text-body);
}

input::placeholder {
  color: var(--text-body);
}

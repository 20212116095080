.header {
  color: var(--text-body);
  display: flex;
  justify-content: space-between;
  padding: 16px;
  width: 100%;

  div {
    padding: 16px;
  }

  @media(max-width: 720px){
    flex-direction: column;
  }
}
.infoUser {
  display: flex;
  flex-direction: row;
  place-content: center;
  align-items: center;

  svg {
    margin-right: 8px;
  }

  h2 {
    font-size: 1rem !important;
  }
}


.build{
  margin-left: 8px;
  font-size: 0.6rem !important;
}

.svg {
  cursor: pointer;
  transition: filter 0.2s;
  margin-left: 16px;
  &:hover{
    filter: brightness(90%);
  }
}

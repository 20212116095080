.tableObs {
  margin-top: 1rem;
  width: 100%;
  color: var(--gray);
  thead {
    background-color: var(--gray-700);
    color: var(--white);
  }

  thead th {
    text-align: center;
    padding: 8px !important;
  }

  tr {
    text-align: center;
    background-color:  var(--gray);
    color: var(--white);
    td {
      padding: 8px !important;
    }
  }
}

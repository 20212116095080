.info {
  display: flex;
  flex-direction: column !important;
  place-content: center;
  align-items: center;

  svg {
    margin: 16px;
  }
}

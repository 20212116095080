.info {
  display: flex;
  flex-direction: column !important;
  place-content: center;
  align-items: center;

  svg {
    margin: 16px;
  }
}

.selectOption {
  height: 3rem;
  padding: 8px;
  background: var(--gray-700);
  color: var(--white);
  border: none !important;
  margin: 16px;

  option {
    padding: 8px;
    border: none !important;
    height: 2rem;
    color: var(--white) !important;
  }
}

.Container {
  width: 100%;

  display: flex;
  place-content: center;
  justify-content: center;
  align-items: center;
  color: var(--white);
}

.color {
  color: #f0f2f5 !important;
}

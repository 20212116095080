.formObservacao{
  display: flex;

  flex-direction:row !important;
  justify-content: flex-end;


  input {
    margin: 0 !important;
    height: 3rem;
    padding: 0 auto !important;
    border: 0 8px !important;
    background: none;
    max-width: 70%;
    text-align: right;
  }

  button {
    background: none !important;
    margin: 0 !important;
    height: 3rem !important;
    padding: 8px;
    margin-left: 8px !important;
    border: none;
    max-width: 80px;
  }
}

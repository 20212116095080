.formObservacao{
  display: flex;

  flex-direction:row;
  justify-content: flex-end;
  width: 100%;

  input {
    margin: 0 !important;
    height: 3rem;
    padding: 0 auto !important;
    border: 0 8px !important;
    background: none;

  }

  button {
    background: none;
    margin: 0 !important;
    height: 3rem !important;
    padding: 8px;
    margin-left: 8px !important;
    border: none;
  }
}
